<div class="me-2">
  <div class="sort-btn-box">
      <button class="btn btn-grey dropdown-toggle" type="button" data-bs-toggle="dropdown">
          <img src="assets/images/icons/icon-sort.svg" /> Sort
      </button>
      <div class="sort-dropdown dropdown-menu">
        <ul class="filter-ui">
          @for (item of items; track $index) {
            <li>
              <a [class.active]="item.active">{{item.label | translate}}</a>
            </li>
          }
        </ul>
      </div>
    </div>
</div>
