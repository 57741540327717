import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';
import { AddNewButtonComponent } from '../../buttons/add-new-button/add-new-button.component';
import { CustomizeButtonComponent } from '../../buttons/customize-button/customize-button.component';
import { SortDropdownComponent } from '../../dropdowns/sort-dropdown/sort-dropdown.component';
import {
  TableColumn,
  TableColumnConfigurable,
  TableQuickSortItem,
} from '../../../../core/modals/table.modal';
import { SearchBoxComponent } from '../../search/search-box/search-box.component';
import { MoreDropdownComponent } from '../../dropdowns/more-dropdown/more-dropdown.component';
import { EditButtonComponent } from '../../buttons/edit-button/edit-button.component';
import { DeleteButtonComponent } from '../../buttons/delete-button/delete-button.component';

@Component({
  selector: 'app-list-page-header',
  standalone: true,
  imports: [
    FormsModule,
    TranslateModule,
    AddNewButtonComponent,
    CustomizeButtonComponent,
    SortDropdownComponent,
    SearchBoxComponent,
    MoreDropdownComponent,
    EditButtonComponent,
    DeleteButtonComponent
  ],
  templateUrl: './list-page-header.component.html',
  styleUrl: './list-page-header.component.scss',
})
export class ListPageHeaderComponent {
  @Input() isSearchEnabled: boolean = true;
  @Input() isColumsConfigurable: boolean = false;
  @Input() isQuickSortEnabled: boolean = false;
  @Input() isAddNewEnabled: boolean = false;
  @Input() isMoreButtonEnabled: boolean = false;
  @Input() isEditButtonEnabled: boolean = false;
  @Input() isDeleteButtonEnabled: boolean = false;

  @Input() title: string = '';
  @Input() addButtonLabel: string = '';
  @Input() editButtonLabel: string = '';
  @Input() deleteButtonLabel: string = '';
  @Input() quickSortItems: TableQuickSortItem[];
  @Input() coloums: TableColumn[] | TableColumnConfigurable[];

  @Output() searchRequest = new EventEmitter<string>();
  @Output() addRequest = new EventEmitter<any>();
  @Output() editRequest = new EventEmitter<any>();
  @Output() deleteRequest = new EventEmitter<any>();
  @Output() toggleRequest = new EventEmitter<any>();

  requestSearch(keyword: string) {
    this.searchRequest.emit(keyword);
  }

  requestAdd() {
    this.addRequest.emit();
  }

  requestEdit() {
    this.editRequest.emit();
  }

  requestDelete() {
    this.deleteRequest.emit();
  }

  requestToggleColoumn(field: string) {
    this.toggleRequest.emit(field);
  }
}
