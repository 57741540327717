<div class="me-2">
  <div class="cutomize-btn-box">
    <button class="btn btn-grey" type="button" data-bs-toggle="dropdown">
      <app-icon [icon]="'customize-button'"></app-icon> Customize
    </button>
    <div class="customize-dropdown  dropdown-menu p-0 "  aria-labelledby="more-dropdown-button">
        <div class="head-block">
            <div class="d-flex justify-content-between">
                <h3>Customize Fields</h3>
                <a><img src="assets/images/icons/icon-close.svg" /></a>
            </div>
            <p>You can customize all the settings from here with respect to your needs</p>
        </div>
        <div class="body-block">
            <div class="cust-fields">
              @for (item of columns; track $index) {
                <div class="d-flex fields justify-content-between align-items-center" [class.disabled]="item.isRequired">
                  <div>
                      <label>{{item.header}}</label>
                      @if (item.isRequired) {}
                  </div>
                  <div class="toggle-btn">
                    <input type="checkbox"
                      [class.disabled]="item.isRequired"
                      [disabled]="item.isRequired"
                      [checked]="item.isEnable"
                      (click)="requestToggle(item.field)">
                  </div>
              </div>
              }
            </div>
        </div>
    </div>
</div>
