import { Component, EventEmitter, Input, Output, input } from '@angular/core';
import {
  TableColumn,
  TableColumnConfigurable,
} from '../../../../core/modals/table.modal';
import { IconComponent } from '../../layout/widgets/icon/icon.component';

@Component({
  selector: 'app-customize-button',
  standalone: true,
  imports: [IconComponent],
  templateUrl: './customize-button.component.html',
  styleUrl: './customize-button.component.scss',
})
export class CustomizeButtonComponent {
  @Input() columns: TableColumn[] | TableColumnConfigurable[];
  @Output() toggleRquest = new EventEmitter<string>();

  requestToggle(field: string): void {
    this.toggleRquest.emit(field);
  }
}
