import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { TableQuickSortItem } from '../../../../core/modals/table.modal';

@Component({
  selector: 'app-sort-dropdown',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './sort-dropdown.component.html',
  styleUrl: './sort-dropdown.component.scss',
})
export class SortDropdownComponent {
  @Input() buttonIcon: string = '';
  @Input() buttonText: string = 'Sort';
  @Input() items: TableQuickSortItem[];

  @Output() sortRequest: EventEmitter<string> = new EventEmitter<string>();

  requestSort(optionLabel: string): void {
    this.sortRequest.emit(optionLabel);
  }
}
